import React, { useState, useEffect } from 'react';
import FacturasView from './FacturasView';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { formatDateMin } from '../../misc/index';
import { httpGet } from '../../http';
import { formatDateMin2 } from '../../misc';

const Facturas = (props) => {

    const [ request, setRequest ] = useState({
        loading: false,
        error: false,
        errorMsg: ''
    });

    const [ estado, setEstado ] = useState({
        empresa: {
            nit: '',
            razonSocial: '',
            nombreAlterno: ''
        },
        proveedor: {
            nit: '',
            razonSocial: '',
            tel: '',
            email: '',
            tercero: {
                razonSocial: ''
            }
        },
        loading: true,
        error: {}
    });

    const [ dates, setDates ] = useState({
        date1: '',
        date2: ''
    });

    const [ facturas, setFacturas ] = useState([]);

    useEffect(() => {
        let newEstado = JSON.parse(sessionStorage.getItem('estado'));
        newEstado.proveedor = JSON.parse(sessionStorage.getItem('proveedor'));
        setEstado(newEstado);
        console.log(newEstado);
        
        setEstado(newEstado);

        //getFacturas(newEstado.proveedor.nit);
    }, []);    

    const getFacturas = async () => {

        setRequest({
            ...request,
            loading: true,
            error: false,
            errorMsg: ''
        });

        const fecha2 = formatDateMin2(new Date());
        const reqFacturas = await httpGet('/api/v1/egresos/estado-facturas?nit=' + estado.proveedor.nit + '&fecha1=' + dates.date1 + '&fecha2=' + dates.date2);
        console.log(reqFacturas);
        if(reqFacturas.data){
            setFacturas(reqFacturas.data);
        }

        setRequest({
            ...request,
            loading: false,
            error: false,
            errorMsg: ''
        });
    }

    const handleViewClick = async (e, factura) => {
        e.preventDefault();
        const resp = await httpGet('/api/v1/egresos/rowid/' + factura.egresoRowid);
        const egreso = resp.data;

        console.log(egreso);

        props.history.push({ pathname: '/portal/egreso-detalle', state: { egreso: egreso } });
    }

    const handleChange = (e) => {
        setDates({
            ...dates,
            [e.target.name]: e.target.value
        });
    }

    const handleClick = () => {
        if((dates.date1.length > 0) && (dates.date2.length > 0)){
            getFacturas();
        } else {
            alert("Por favor ingrese las fechas correctamente.")
        }
    }

    const formatData = (data) => {
        let newData = [];
        let no = 1;
        for (const reg of data){
            let newReg = {
                no: no,
                centro_operacion: reg.idCoCruce,
                documento: reg.prefijoCruce + '-' + reg.consecutivoDocCruce,
                fecha_documento: formatDateMin(new Date(reg.fecha)),
                fecha_vencimiento: formatDateMin(new Date(reg.fechaVcto)),
                valor_descuento_pp: reg.valorDescuentoPP,
                valor_saldo: reg.valorFactura,
                pagado: reg.egresoRowid > 0 ? 'SI' : 'NO'
            }
            newData.push(newReg)
        }
        return newData;
    }
    
    const exportToExcel = () => {
        const fileType =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";
        const ws = XLSX.utils.json_to_sheet(formatData(facturas));
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, 'estado' + fileExtension);
    };

    return <FacturasView request={request} facturas={facturas} onViewClick={handleViewClick} dates={dates} onChange={handleChange} onClick={handleClick} onExportClick={exportToExcel}  />
}

export default Facturas;