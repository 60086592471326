import React, { useState, useEffect } from 'react';
import Button from '../../components/button/Button';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import ReactPDF, { PDFDownloadLink, PDFViewer, PDFRenderer } from '@react-pdf/renderer/dist/react-pdf.es';
import { formatNumber, numberToLetters, getCurrentDate, formatDateMin } from '../../misc/index';

const Certificado = (props) => {

    const {  retencion  } = props;

    return(
        <Document>
            <Page size="LETTER" style={styles.pagina}>
                <View style={styles.header}>
                    <Text style={styles.title}>{ retencion.empresa.razonSocial }</Text>
                    <Text style={styles.title}>{ retencion.empresa.nit } - { retencion.empresa.digitoVerificacion }</Text>
                    <Text style={styles.title}>{ retencion.empresa.ciudad }</Text>
                    <Text style={styles.title}>{ retencion.tipo.titulo }</Text>
                    <Text style={styles.title}>{ retencion.tipo.periodoGravable }</Text>                    
                </View>
                <View style={styles.proveedorContainer}>
                    <Text style={styles.datoProveedor}>RETENIDO: { retencion.proveedor.razonSocial }</Text>
                    <Text style={styles.datoProveedor}>N.I.T.: { retencion.proveedor.nit }</Text>
                    <Text style={styles.datoProveedor}>DIRECCIÓN: { retencion.proveedor.direccion }</Text>
                    <Text style={styles.datoProveedor}>CIUDAD: { retencion.proveedor.ciudad }</Text>
                </View>

                <View style={styles.ciudadContainer}>
                    <Text style={styles.datoProveedor}>CIUDAD DONDE SE PRACTICO LA RETENCIÓN: { retencion.tipo.ciudad }</Text>
                    <Text style={styles.datoProveedor}>CIUDAD DONDE SE CONSIGNO LA RETENCIÓN: { retencion.tipo.ciudad }</Text>
                </View>

                <View style={styles.bodyContainer}>
                    <View style={styles.detalleContainer}>
                        <View style={styles.tabla}>
                            <View style={styles.th}>
                                <Text style={[styles.tablaCampoTitulo, {width: 270}]}>CONCEPTO</Text>
                                <Text style={[styles.tablaCampoTitulo, {width: 40}]}>TASA</Text>
                                <Text style={[styles.tablaCampoTitulo, {width: 100}]}>BASE</Text>
                                <Text style={[styles.tablaCampoTitulo, {width: 100}]}>RETENCIÓN</Text>
                            </View>
                            {
                                retencion.data.map(
                                    (rete, index) => {
                                        return(
                                            <View key={index} style={styles.tr}>
                                                <Text style={[styles.tablaCampo, {width: 270}]}>{ rete.cuentaDescripcion }</Text>
                                                <Text style={[styles.tablaCampo, {width: 40}]}>{ rete.tasa }%</Text>
                                                <Text style={[styles.tablaCampo, {width: 100}]}>${ formatNumber(rete.base) }</Text>
                                                <Text style={[styles.tablaCampo, {width: 100}]}>${ formatNumber(rete.valorRetencion) }</Text>
                                            </View>
                                        );
                                    }
                                )
                            }
                            <View style={styles.tr}>
                                <Text style={[styles.tablaCampoTotal, {width: 310}]}>TOTAL:</Text>
                                <Text style={[styles.tablaCampo, {width: 100}]}>${ formatNumber(retencion.total.base) }</Text>
                                <Text style={[styles.tablaCampo, {width: 100}]}>${ formatNumber(retencion.total.retencion) }</Text>
                            </View>
                        </View>
                    </View>
                    <View style={ styles.valor }>
                            <Text>VALOR: { numberToLetters(retencion.total.retencion) } PESOS MCTE.</Text>
                    </View>
                    <View style={ styles.pie }>
                            <Text style={{ marginBottom: 10}}>FECHA DE EXPEDICIÓN: { formatDateMin(new Date()) }</Text>
                            <Text>OBSERVACIONES: FORMA CONTINUA IMPRESA POR COMPUTADORA, NO NECESITA FIRMA AUTOGRAFA (ART. 10 D.R. 836/91)</Text>
                    </View>
                </View>
                <View style={styles.pageNumber}>
                    <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (`${pageNumber} / ${totalPages}`)} fixed />
                </View>
            </Page>
        </Document>
    );
}

const CertificadoPDF = (props) => {

    useEffect(() => {

        if(!props.history.location.state.retencion){
            props.history.push('/portal');
            return 0;
        }

    }, []);

    return(
        <>
            <div>
                <Button onClick={ () => { props.history.goBack() } } >Volver</Button>
            </div>
            <br />
            <PDFViewer style={{ width: '100%', height: '100vh' }}>
                <Certificado retencion={ props.history.location.state.retencion } />
            </PDFViewer>
        </>
    );
}

const styles = StyleSheet.create({
    header: {
        alignItems: 'center',
        marginBottom: 50
    },
    title: {
        fontSize: 15,
        marginBottom: 20   
    },
    subTitle: {
        fontSize: 20,    
    },
    proveedorContainer: {
        fontSize: 12,
        marginBottom: 20
    },
    ciudadContainer: {
        fontSize: 12,
        marginBottom: 20
    },
    bodyContainer: {
        
    },
    datoProveedor: {
        marginBottom: 10
    },
    encabezadoContainer: {
        fontSize: 10,
        marginBottom: 10
    },
    campoContainer: {
        flexDirection: 'row',
    },
    campoTitulo: {
        
    },
    tabla: {
        borderWidth: 1,
        borderRadius: 5,
        fontSize: 10,
        marginBottom: 30
    },
    th: {
        flexDirection: 'row',
    },
    tr: {
        flexDirection: 'row',
    },
    tablaCampoTitulo: {
        backgroundColor: 'black',
        color: 'white',
        /*width: 128,*/
        borderRightWidth: 1,
        borderRightColor: 'white',
        paddingLeft: 5
        
    },
    tablaCampo: {
        /*width: 128,*/
        color: 'black',
        borderRightWidth: 1,
        borderRightColor: 'black',
        paddingLeft: 5,
        borderBottomWidth: 1
    },
    tablaCampoTotal: {
        /*width: 128,*/
        color: 'black',
        paddingLeft: 5,
        borderBottomWidth: 1
    },
    valor: {
        fontSize: 12,
        marginBottom: 80
    },
    pie: {
        fontSize: 7
    },
    pagina: {
        padding: 50
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    }
});

export default CertificadoPDF;