import React, { useEffect } from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import ReactPDF, { PDFDownloadLink, PDFViewer, PDFRenderer } from '@react-pdf/renderer/dist/react-pdf.es';
import Button from '../../components/button/Button';
import { formatNumber, formatDateMin } from '../../misc/index';


const Egreso = (props) => {

    const { egreso } = props;

    return(
        <Document>
            <Page size="LETTER" orientation="landscape" style={styles.pagina} wrap>
                <View style={styles.pageNumber}>
                    <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (`${pageNumber} / ${totalPages}`)} fixed />
                </View>
                <View style={[styles.block, { marginBottom: 10 }]}>
                    <Text style={styles.title}>{ egreso.encabezado.tipoDocDescripcion }</Text>
                    <View style={styles.fieldRow}>
                        <Text style={styles.fieldName}>{egreso.empresa.razonSocial}</Text>
                        
                        <View style={styles.field}>
                            <Text style={styles.fieldName}>Número: { egreso.encabezado.idCo + '-' + egreso.encabezado.tipoDoc + '-' + egreso.encabezado.numeroDoc }</Text>
                            <Text style={styles.fieldName}>Valor: { formatNumber(egreso.encabezado.valor) }</Text>
                        </View>
                    </View>
                    <View style={styles.fieldRow}>
                        <View style={styles.field}>
                            <Text style={styles.fieldName}>NIT: </Text>
                            <Text style={styles.fieldName}>{egreso.empresa.nit}-{egreso.empresa.digitoVerificacion}</Text>
                        </View>
                        <View style={styles.field}>
                            <Text style={styles.fieldName}>Fecha: </Text>
                            <Text style={styles.fieldName}>{ formatDateMin(new Date(egreso.encabezado.fechaDoc)) }</Text>
                        </View>
                    </View>
                </View>

                <View style={[styles.block, { marginBottom: 10 }]}>
                    <View style={styles.fieldRow}>
                        <View style={styles.field}>
                            <Text style={styles.fieldName}>Tercero: </Text>
                            <Text style={styles.fieldName}>{ egreso.proveedor.razonSocial }</Text>
                        </View>
                        <View style={styles.field}>
                            <Text style={styles.fieldName}>Identificación: </Text>
                            <Text style={styles.fieldName}>{ egreso.proveedor.nit }</Text>
                        </View>
                    </View>
                </View>

                {/*
                <View style={{ marginBottom: 10 }}>
                    <View style={{ flexDirection: 'row', borderTop: 2, borderWidth: 2, }}>
                        <View style={{ width: '20%', fontSize: 10, textAlign: 'center', justifyContent: 'center', borderRightWidth: 2, height: 20 }}>
                            <Text>Cuenta</Text>
                        </View>
                        <View style={{ width: '50%', fontSize: 10, textAlign: 'center', justifyContent: 'center', borderRightWidth: 2, height: 20 }}>
                            <Text>Cuenta Bancaria</Text>
                        </View>
                        <View style={{ width: '30%', fontSize: 10, textAlign: 'center', justifyContent: 'center', height: 20 }}>
                            <Text>Valor Consignado</Text>
                        </View>
                    </View>
                    <View style={{ flexDirection: 'row', border: 2, borderWidth: 2, marginTop: -2}}>
                        <View style={{ width: '20%', fontSize: 10, textAlign: 'center', justifyContent: 'center', borderRightWidth: 2, height: 20 }}>
                            <Text></Text>
                        </View>
                        <View style={{ width: '50%', fontSize: 10, textAlign: 'center', justifyContent: 'center', borderRightWidth: 2, height: 20 }}>
                            <Text></Text>
                        </View>
                        <View style={{ width: '30%', fontSize: 10, textAlign: 'center', justifyContent: 'center', height: 20 }}>
                            <Text>${ formatNumber(egreso.encabezado.valor) }</Text>
                        </View>
                    </View>
                </View>
                */}

                <View style={[styles.block, { marginBottom: 10 }]}>
                    <View style={styles.fieldRow}>
                        <View style={styles.field}>
                            <Text style={styles.fieldName}>Notas: </Text>
                            <Text style={styles.fieldName}>{ egreso.encabezado.notas }</Text>
                        </View>
                    </View>
                </View>

                <View style={{ marginBottom: 10 }}>
                    <View style={{ flexDirection: 'row', borderTop: 2, borderWidth: 2 }}>
                        <View style={{ width: '10%', fontSize: 10, textAlign: 'center', justifyContent: 'center', borderRightWidth: 2, height: 20 }}>
                            <Text>Cod. Cuenta</Text>
                        </View>
                        <View style={{ width: '20%', fontSize: 10, textAlign: 'center', justifyContent: 'center', borderRightWidth: 2, height: 20 }}>
                            <Text>Descripcion Cuenta</Text>
                        </View>
                        <View style={{ width: '5%', fontSize: 10, textAlign: 'center', justifyContent: 'center', borderRightWidth: 2, height: 20 }}>
                            <Text>C. O.</Text>
                        </View>
                        <View style={{ width: '10%', fontSize: 10, textAlign: 'center', justifyContent: 'center', borderRightWidth: 2, height: 20 }}>
                            <Text>Tercero</Text>
                        </View>
                        <View style={{ width: '25%', fontSize: 10, textAlign: 'center', justifyContent: 'center', borderRightWidth: 2, height: 20 }}>
                            <Text>Razon Social</Text>
                        </View>
                        <View style={{ width: '15%', fontSize: 10, textAlign: 'center', justifyContent: 'center', borderRightWidth: 2, height: 20 }}>
                            <Text>Debitos</Text>
                        </View>
                        <View style={{ width: '15%', fontSize: 10, textAlign: 'center', justifyContent: 'center', height: 20 }}>
                            <Text>Creditos</Text>
                        </View>
                    </View>

                    {
                        egreso.movimiento.detalles.map(
                            (detalle, index) => {
                                console.log(detalle);
                                return(
                                    <View style={{ flexDirection: 'row', borderLeftWidth: 2, borderRightWidth: 2, marginTop: -2 }}>
                                        <View style={{ width: '10%', fontSize: 10, textAlign: 'left', justifyContent: 'center', borderRightWidth: 2, paddingLeft: 2, paddingTop: 8 }}>
                                            <Text>{ detalle.cuentaId }</Text>
                                        </View>
                                        <View style={{ width: '20%', fontSize: 10, textAlign: 'left', justifyContent: 'center', borderRightWidth: 2, paddingLeft: 2, paddingTop: 8 }}>
                                            <Text>{ detalle.cuentaDescripcion }</Text>
                                        </View>
                                        <View style={{ width: '5%', fontSize: 10, textAlign: 'left', justifyContent: 'center', borderRightWidth: 2, paddingLeft: 2, paddingTop: 8 }}>
                                            <Text>{ detalle.idCo }</Text>
                                        </View>
                                        <View style={{ width: '10%', fontSize: 10, textAlign: 'left', justifyContent: 'center', borderRightWidth: 2, paddingLeft: 2, paddingTop: 8 }}>
                                            <Text>{ detalle.nit }</Text>
                                        </View>
                                        <View style={{ width: '25%', fontSize: 10, textAlign: 'left', justifyContent: 'center', borderRightWidth: 2, paddingLeft: 2, paddingTop: 8 }}>
                                            <Text>{ detalle.razonSocial }</Text>
                                        </View>
                                        <View style={{ width: '15%', fontSize: 10, textAlign: 'left', justifyContent: 'center', borderRightWidth: 2, paddingLeft: 2, paddingTop: 8 }}>
                                            <Text>${ formatNumber(detalle.valorDb) }</Text>
                                        </View>
                                        <View style={{ width: '15%', fontSize: 10, textAlign: 'left', justifyContent: 'center', paddingLeft: 2, paddingTop: 8 }}>
                                            <Text>${ formatNumber(detalle.valorCr) }</Text>
                                        </View>
                                    </View>
                                );
                            }
                        )
                    }

                    <View style={{ flexDirection: 'row', borderTop: 2, borderWidth: 2 }}>
                        <View style={{ width: '70%', fontSize: 10, textAlign: 'center', justifyContent: 'center', borderRightWidth: 2, height: 20 }}>
                            <Text>Sumas Iguales:</Text>
                        </View>
                        <View style={{ width: '15%', fontSize: 10, textAlign: 'left', justifyContent: 'center', borderRightWidth: 2, height: 20, paddingLeft: 2 }}>
                            <Text>${ formatNumber(egreso.movimiento.totalDebito) }</Text>
                        </View>
                        <View style={{ width: '15%', fontSize: 10, textAlign: 'left', justifyContent: 'center', height: 20, paddingLeft: 2 }}>
                            <Text>${ formatNumber(egreso.movimiento.totalCredito) }</Text>
                        </View>
                    </View>
                </View>

                

                <View style={{ marginBottom: 10 }}>
                    <View style={{ flexDirection: 'row', borderTop: 2, borderWidth: 2 }}>
                        <View style={{ width: '5%', fontSize: 10, textAlign: 'center', justifyContent: 'center', borderRightWidth: 2, height: 20 }}>
                            <Text>No.</Text>
                        </View>
                        <View style={{ width: '10%', fontSize: 10, textAlign: 'center', justifyContent: 'center', borderRightWidth: 2, height: 20 }}>
                            <Text>Codigo cuenta</Text>
                        </View>
                        <View style={{ width: '55%', fontSize: 10, textAlign: 'center', justifyContent: 'center', borderRightWidth: 2, height: 20 }}>
                            <Text>Descripción cuenta</Text>
                        </View>
                        <View style={{ width: '15%', fontSize: 10, textAlign: 'center', justifyContent: 'center', borderRightWidth: 2, height: 20 }}>
                            <Text>Valor retención</Text>
                        </View>
                        <View style={{ width: '15%', fontSize: 10, textAlign: 'center', justifyContent: 'center', borderRightWidth: 2, height: 20 }}>
                            <Text>Base</Text>
                        </View>
                    </View>

                    {
                        egreso.retenciones.detalles.map(
                            (detalle, index) => {
                                console.log(detalle);
                                return(
                                    <View style={{ flexDirection: 'row', borderLeftWidth: 2, borderRightWidth: 2, marginTop: -2 }}>
                                        <View style={{ width: '5%', fontSize: 10, textAlign: 'left', justifyContent: 'center', borderRightWidth: 2, paddingLeft: 2, paddingTop: 8 }}>
                                            <Text>{ index + 1 }</Text>
                                        </View>
                                        <View style={{ width: '10%', fontSize: 10, textAlign: 'left', justifyContent: 'center', borderRightWidth: 2, paddingLeft: 2, paddingTop: 8 }}>
                                            <Text>{ detalle.cuenta }</Text>
                                        </View>
                                        <View style={{ width: '55%', fontSize: 10, textAlign: 'left', justifyContent: 'center', borderRightWidth: 2, paddingLeft: 2, paddingTop: 8 }}>
                                            <Text>{ detalle.cuentaDescripcion }</Text>
                                        </View>
                                        <View style={{ width: '15%', fontSize: 10, textAlign: 'left', justifyContent: 'center', borderRightWidth: 2, paddingLeft: 2, paddingTop: 8 }}>
                                            <Text>${ formatNumber(detalle.valorCr - detalle.valorDb) }</Text>
                                        </View>
                                        <View style={{ width: '15%', fontSize: 10, textAlign: 'left', justifyContent: 'center', paddingLeft: 2, paddingTop: 8 }}>
                                            <Text>${ formatNumber(detalle.valorBase) }</Text>
                                        </View>
                                    </View>
                                );
                            }
                        )
                    }

                    <View style={{ flexDirection: 'row', borderTop: 2, borderWidth: 2 }}>
                        <View style={{ width: '70%', fontSize: 10, textAlign: 'center', justifyContent: 'center', borderRightWidth: 2, height: 20 }}>
                            <Text>Sumas Iguales:</Text>
                        </View>
                        <View style={{ width: '15%', fontSize: 10, textAlign: 'left', justifyContent: 'center', borderRightWidth: 2, height: 20, paddingLeft: 2 }}>
                            <Text>${ formatNumber(egreso.retenciones.totalCredito - egreso.retenciones.totalDebito) }</Text>
                        </View>
                        <View style={{ width: '15%', fontSize: 10, textAlign: 'left', justifyContent: 'center', height: 20, paddingLeft: 2 }}>
                            <Text>${ formatNumber(egreso.retenciones.totalBase) }</Text>
                        </View>
                    </View>
                </View>

                

                <View style={{ marginBottom: 10, borderBottom: 2 }}>
                    <View style={{ flexDirection: 'row', borderTop: 2, borderWidth: 2 }} fixed>
                        <View style={{ width: '5%', fontSize: 10, textAlign: 'center', justifyContent: 'center', borderRightWidth: 2, height: 22 }}>
                            <Text>Reg.</Text>
                        </View>
                        <View style={{ width: '5%', fontSize: 10, textAlign: 'center', justifyContent: 'center', borderRightWidth: 2, height: 22 }}>
                            <Text>C. O.</Text>
                        </View>
                        <View style={{ width: '14%', fontSize: 10, textAlign: 'center', justifyContent: 'center', borderRightWidth: 2, height: 22 }}>
                            <Text>Doc. Cruce</Text>
                        </View>
                        <View style={{ width: '28%', fontSize: 10, textAlign: 'center', justifyContent: 'center', borderRightWidth: 2, height: 22 }}>
                            <Text>Detalle</Text>
                        </View>
                        <View style={{ width: '12%', fontSize: 10, textAlign: 'center', justifyContent: 'center', borderRightWidth: 2, height: 22 }}>
                            <Text>Descuentos</Text>
                        </View>
                        <View style={{ width: '12%', fontSize: 10, textAlign: 'center', justifyContent: 'center', borderRightWidth: 2, height: 22 }}>
                            <Text>Retenciones</Text>
                        </View>
                        <View style={{ width: '12%', fontSize: 10, textAlign: 'center', justifyContent: 'center', borderRightWidth: 2, height: 22 }}>
                            <Text>Valor Descuento P.P.</Text>
                        </View>
                        <View style={{ width: '12%', fontSize: 10, textAlign: 'center', justifyContent: 'center', borderRightWidth: 2, height: 22 }}>
                            <Text>Valor</Text>
                        </View>
                    </View>

                    {
                        egreso.documentos.map(
                            (documento, index) => {
                                return(
                                    <View style={{ flexDirection: 'row', borderLeftWidth: 2, borderRightWidth: 2, marginTop: -2, paddingTop: 2 }}>
                                        <View style={{ width: '5%', fontSize: 10, textAlign: 'left', justifyContent: 'center', borderRightWidth: 2, paddingLeft: 2, paddingTop: 8 }}>
                                            <Text>{ (index + 1) }</Text>
                                        </View>
                                        <View style={{ width: '5%', fontSize: 10, textAlign: 'left', justifyContent: 'center', borderRightWidth: 2, paddingLeft: 2, paddingTop: 8 }}>
                                            <Text>{ documento.docIdCoMov }</Text>
                                        </View>
                                        <View style={{ width: '14%', fontSize: 10, textAlign: 'left', justifyContent: 'center', borderRightWidth: 2, paddingLeft: 2, paddingTop: 8 }}>
                                            <Text>{ documento.docCruTipoDoc + ' ' + documento.docCruNumeroDoc }</Text>
                                        </View>
                                        <View style={{ width: '28%', fontSize: 10, textAlign: 'left', justifyContent: 'center', borderRightWidth: 2, paddingLeft: 2, paddingTop: 8 }}>
                                            <Text>{ documento.notas }</Text>
                                        </View>
                                        <View style={{ width: '12%', fontSize: 10, textAlign: 'left', justifyContent: 'center', borderRightWidth: 2, paddingLeft: 2, paddingTop: 8 }}>
                                            <Text>${ formatNumber(documento.factDscto) }</Text>
                                        </View>
                                        <View style={{ width: '12%', fontSize: 10, textAlign: 'left', justifyContent: 'center', borderRightWidth: 2, paddingLeft: 2, paddingTop: 8 }}>
                                            <Text>${ formatNumber(documento.factValorRetencion) }</Text>
                                        </View>
                                        <View style={{ width: '12%', fontSize: 10, textAlign: 'left', justifyContent: 'center', borderRightWidth: 2, paddingLeft: 2, paddingTop: 8 }}>
                                            <Text>${ formatNumber(documento.valorAplicadoPP) }</Text>
                                        </View>
                                        <View style={{ width: '12%', fontSize: 10, textAlign: 'left', justifyContent: 'center', borderRightWidth: 2, paddingLeft: 2, paddingTop: 8 }}>
                                            <Text>${ formatNumber(documento.valorPagoAlt) }</Text>
                                        </View>
                                    </View>
                                );
                            }
                        )
                    }
                </View>

                
            </Page>
        </Document>
    );
}

const EgresoPDF = (props) => {

    useEffect(() => {

        if(!props.history.location.state.egreso){
            props.history.push('/portal');
            return 0;
        }

    }, []);

    return(
        <>
            <div>
                <Button onClick={ () => { props.history.goBack() } } >Volver</Button>
            </div>
            <br />
            <PDFViewer style={{ width: '100%', height: '100vh' }}>
                <Egreso egreso={ props.history.location.state.egreso } />
            </PDFViewer>
        </>
    );
}

const styles = StyleSheet.create({
    block: {
        borderWidth: 2,
        padding: 5
    },
    field: {
        flexDirection: 'row'
    },
    fieldRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 5
    },
    header: {
        alignItems: 'center',
        marginBottom: 50
    },
    title: {
        fontSize: 15,
        textAlign: 'center'
    },
    fieldName: {
        fontSize: 10,
        fontWeight: 'ultrabold'
    },
    subTitle: {
        fontSize: 20,    
    },
    proveedorContainer: {
        fontSize: 10,
        marginBottom: 10
    },
    bodyContainer: {
        
    },
    encabezadoContainer: {
        fontSize: 10,
        marginBottom: 10
    },
    campoContainer: {
        flexDirection: 'row',
    },
    campoTitulo: {
        
    },
    tabla: {
        borderWidth: 1,
        borderRadius: 5,
        fontSize: 10
    },
    th: {
        flexDirection: 'row',
    },
    tr: {
        flexDirection: 'row',
    },
    tablaCampoTitulo: {
        backgroundColor: 'black',
        color: 'white',
        /*width: '100%',*/
        borderRightWidth: 1,
        borderRightColor: 'white',
        paddingLeft: 5
        
    },
    tablaCampo: {
        /*width: '100%',*/
        color: 'black',
        borderRightWidth: 1,
        borderRightColor: 'black',
        paddingLeft: 5,
        borderBottomWidth: 1
    },
    tablaCampoTotal: {
        width: 102,
        color: 'black',
        paddingLeft: 5,
        borderBottomWidth: 1
    },
    pagina: {
        padding: 50
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 12,
        top: 1,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    }
});

export default EgresoPDF;