import React, { useState } from 'react';
import Title from '../../components/title/Title';
import { Spinner, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { formatNumber, formatDateMin } from '../../misc/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import { Link } from 'react-router-dom';
import DateField from '../../components/dateField';
import Button from '../../components/button/Button';

import './Facturas.css'

const IconoEstado = (props) => {
    return(
        <>
            { props.estado > 0 ?
                <OverlayTrigger placement='bottom' overlay={
                    <Tooltip id='error'>
                    Pago efectuado
                    </Tooltip>
                }>
                    <div className='factura-estado-icon-container'>
                        <FontAwesomeIcon className='view-icon' icon={faCheckCircle} />
                    </div>
                </OverlayTrigger>
            :
                <OverlayTrigger placement='bottom' overlay={
                    <Tooltip id='error'>
                    Sin pago
                    </Tooltip>
                }>
                    <div className='factura-estado-icon-container-uncheck'>
                        <FontAwesomeIcon className='view-icon' icon={faMinusCircle} />
                    </div>
                </OverlayTrigger>
            }
        </>
    );
}

const FacturasView = (props) => {

    const { dates, onChange, onClick, onExportClick } = props;

   return(
    <div className='page-container'>
        <Title>Estado de Facturas</Title>

        <div className='datefields-container'>
            <DateField label="Fecha inicial:" name='date1' value={dates.date1} onChange={onChange} /> 
            <DateField label="Fecha final:" name='date2' value={dates.date2} onChange={onChange} />
            <Button onClick={onClick}>Consultar</Button>
        </div>

        {props.request.loading ?
            <div className='loading-spinner'>
                <Spinner animation="border" variant="primary" />
            </div>
        :
            <div className='page-body'>
                { props.facturas.length > 0 &&
                <div>
                    <button onClick={onExportClick}>Descargar Xlsx</button>
                </div>
                }
                
                <table className='info-table'>
                    <thead>
                        <tr>
                            <th>NO.</th>
                            <th>CENTRO DE OPERACION</th>
                            <th>DOCUMENTO</th>
                            <th>FECHA DEL DOCUMENTO</th>
                            <th>FECHA DE VENCIMIENTO</th>
                            <th>VALOR DESCUENTO P. PAGO</th>
                            <th>VALOR SALDO</th>
                            <th>ESTADO</th>
                            <th>VER</th>
                        </tr>
                    </thead>
                    <tbody>
                        { 
                            props.facturas.map(
                                (factura, index) => {
                                    return(
                                        <tr key={index}>
                                            <td>{ index + 1 }</td>
                                            <td>{ factura.idCoCruce }</td>
                                            <td>{ factura.prefijoCruce + '-' + factura.consecutivoDocCruce }</td>
                                            <td>{ formatDateMin(new Date(factura.fecha)) }</td>
                                            <td>{ formatDateMin(new Date(factura.fechaVcto)) }</td>
                                            <td>${ formatNumber(factura.valorDescuentoPP) }</td>
                                            <td>${ formatNumber(factura.valorFactura) }</td>
                                            <td><IconoEstado estado={factura.egresoRowid} /></td>
                                            <td>{ factura.egresoRowid > 0 && <div className='view-icon-container'><Link to={{ pathname: "" }} onClick={(e) => { props.onViewClick(e, factura) }}><FontAwesomeIcon className='view-icon' icon={faEye} /></Link></div>}</td>
                                        </tr>
                                    );
                                }
                            )
                        }
                    </tbody>
                </table>
            </div>
        }
    </div>
);
}

export default FacturasView;