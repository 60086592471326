import React, { useState, useEffect } from 'react';
import { Navbar, Nav, NavDropdown, Form, FormControl, Button } from 'react-bootstrap';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { faAward } from '@fortawesome/free-solid-svg-icons';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { faStore } from '@fortawesome/free-solid-svg-icons';
import { faUsersCog, faCog } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import AdminHome from '../adminLayout/AdminHome';
import Proveedores from '../proveedores/Proveedores';
import CentrosOperacion from '../centrosOperacion/CentrosOperacion';
import Profile from '../profile/Profile';
import Admins from '../admins/Admins';
import Lapsos from '../lapsos/Lapsos';
import Configuracion from '../configuracion/Configuracion';
import logo from '../../../img/logo.png';
import './AdminLayout.css'

const MenuOption = (props) =>  {
    return(
        <div className='menu-option-wrapper'>
            <div className='menu-icon-container' onMouseOver={ props.onMouseOver } onMouseLeave={ props.onMouseLeave }>
                <Link style={{ color: 'white' }} to={props.to}><FontAwesomeIcon className='menu-icon' icon={props.icon} /></Link>
            </div>
            <div className={props.className}>{props.children}</div>
        </div>
    );
}

const AdminLayout = (props) => {

    const [ estado, setEstado ] = useState({
        empresa: {
            nit: '',
            razonSocial: '',
            nombreAlterno: ''
        },
        admin: {
            email: '',
            nombre: ''
        },
        loading: true,
        error: {}
    });

    useEffect(() => {
        if(!sessionStorage.getItem('estado')){
            props.history.push('/');
            return 0;
        }

        if(!sessionStorage.getItem('adminUser')){
            props.history.push('/admin-login');
            return 0;
        }

        console.log(JSON.parse(sessionStorage.getItem('estado')));

        let newEstado = JSON.parse(sessionStorage.getItem('estado'));
        newEstado.admin = JSON.parse(sessionStorage.getItem('adminUser'));
        console.log(newEstado);
        
        setEstado(newEstado);

    }, []);

    const [ menu, setMenu ] = useState({
        menuClass: 'menu',
        menuOptionText: 'menu-option-text'
    });

    const handleMouseOver = () => {
        setMenu({
            ...menu,
            menuClass: 'menu-active',
            menuOptionText: 'menu-option-text-active'
        });
    }

    const handleMouseLeave = () => {
        setMenu({
            ...menu,
            menuClass: 'menu',
            menuOptionText: 'menu-option-text'
        });
    }

    const exit = () => {
        sessionStorage.removeItem('proveedor');
        props.history.push('/');
    }

    return(
        <div>
            <Navbar fixed="top" bg="dark" variant="dark" collapseOnSelect expand="md" style={{ height: '40px', justifyContent: 'space-between' }}> 
                <Navbar.Brand className="brand-title" href="#home">Portal de administración</Navbar.Brand>
                <Navbar.Text>Hola { estado.admin.nombre } <a href="#" onClick={exit}>Salir</a></Navbar.Text>
            </Navbar>
        
        
            <div className='home-wrapper'>
                <div className={menu.menuClass}>
                    <div className='menu-logo-wrapper'>
                    </div>
                    <MenuOption className={menu.menuOptionText} icon={faUsers} to='/admin/proveedores' onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave} >Proveedores</MenuOption>
                    <MenuOption className={menu.menuOptionText} icon={faStore} to='/admin/centros-operacion' onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave} >Configuracion centros de operación</MenuOption>
                    <MenuOption className={menu.menuOptionText} icon={faCog} to='/admin/conf' onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}>Configuración</MenuOption>
                    <MenuOption className={menu.menuOptionText} icon={faUsersCog} to='/admin/admins' onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}>Administradores</MenuOption>
                    <MenuOption className={menu.menuOptionText} icon={faUserCircle} to='/admin/profile' onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}>Perfil</MenuOption>
                </div>
            </div>

            <div className='admin-logo-container'>
                <img className='admin-logo' src={logo} />
            </div>

            <div className='home-main'>
                <Switch>
                    <Route path="/admin/profile" render={() => (<Profile history={props.history} />)} />
                    <Route path="/admin/admins" render={() => (<Admins history={props.history} />)} />
                    <Route path="/admin/periodos-reteiva" render={() => (<Lapsos history={props.history} />)} />
                    <Route path="/admin/centros-operacion" render={() => (<CentrosOperacion history={props.history} />)} />
                    <Route path="/admin/proveedores" render={() => (<Proveedores history={props.history} />)} />
                    <Route path="/admin/conf" render={() => (<Configuracion history={props.history} />)} />
                    <Route path="/admin" render={() => (<AdminHome history={props.history} />)} />
                </Switch>
            </div>

        </div>
    );
}

export default AdminLayout;